var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row maindashboard-director" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
          [
            _c("div", { staticClass: "col-12 no-padding" }, [
              _c("div", { staticClass: "cardcontents" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$language("(금일) 진행중인 업무")) + " "
                  ),
                  _c(
                    "div",
                    { staticClass: "card-more main-header-input" },
                    [
                      _c("c-plant", {
                        attrs: {
                          type: "search",
                          name: "plantCd1",
                          isFirstValue: false,
                          label: "",
                        },
                        on: { datachange: _vm.getWorkProgress },
                        model: {
                          value: _vm.plantCd1,
                          callback: function ($$v) {
                            _vm.plantCd1 = $$v
                          },
                          expression: "plantCd1",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "card-body",
                    staticStyle: { height: "213px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.workProgressList, function (progress, idx) {
                        return _c(
                          "div",
                          {
                            key: idx,
                            staticClass:
                              "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                          },
                          [
                            _c(
                              "q-btn",
                              {
                                staticClass: "btn-fixed-width-height",
                                attrs: {
                                  dense: "",
                                  icon: _vm.iconItems[idx],
                                  align: "left",
                                  color: _vm.colorItems2[idx],
                                  outline: "",
                                  unelevated: "",
                                  label: progress.workName,
                                },
                              },
                              [
                                _c(
                                  "q-badge",
                                  {
                                    attrs: {
                                      color: "red",
                                      floating: "",
                                      transparent: "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(progress.workCount) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-12 no-padding" }, [
              _c("div", { staticClass: "cardcontents" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v(" " + _vm._s(_vm.$language("개선 진행 목록")) + " "),
                  _c(
                    "div",
                    { staticClass: "card-more main-header-input" },
                    [
                      _c("c-plant", {
                        attrs: {
                          type: "search",
                          name: "plantCd3",
                          isFirstValue: false,
                          label: "",
                        },
                        on: { datachange: _vm.getImprProgress },
                        model: {
                          value: _vm.plantCd3,
                          callback: function ($$v) {
                            _vm.plantCd3 = $$v
                          },
                          expression: "plantCd3",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "card-body nopadgrid" },
                  [
                    _c("c-table", {
                      ref: "grid2",
                      attrs: {
                        tableId: "grid2",
                        isTitle: false,
                        isDashboard: true,
                        columnSetting: false,
                        usePaging: false,
                        filtering: false,
                        isFullScreen: false,
                        hideBottom: true,
                        gridHeight: "217px",
                        columns: _vm.grid2.columns,
                        data: _vm.grid2.data,
                      },
                      on: { linkClick: _vm.linkClickImp },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
          [
            _c(
              "div",
              {
                ref: "main-dashborad",
                staticClass: "cardcontents directorMainLayer",
              },
              [
                _c(
                  "div",
                  { staticClass: "card-header non-label-icon mapheader" },
                  [
                    _vm._v(" 작업허가서 현황 "),
                    _c(
                      "q-btn",
                      {
                        staticClass: "mainmapstart fullscreen-btn",
                        attrs: {
                          flat: "",
                          round: "",
                          color: "grey",
                          icon: _vm.fullscreen
                            ? "fullscreen_exit"
                            : "fullscreen",
                        },
                        on: { click: _vm.clickFullScreen },
                      },
                      [
                        _vm.fullscreen
                          ? _c("q-tooltip", [
                              _vm._v(
                                " " + _vm._s(_vm.$language("원래대로")) + " "
                              ),
                            ])
                          : _c("q-tooltip", [
                              _vm._v(
                                " " + _vm._s(_vm.$language("전체화면")) + " "
                              ),
                            ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-more main-header-input main-header-input2 main-header-input2-director",
                      },
                      [
                        _c("c-datepicker", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.mapObj.activeTask === 1,
                              expression: "mapObj.activeTask===1",
                            },
                          ],
                          attrs: {
                            range: true,
                            appendToBody: false,
                            name: "workPermitPeriod",
                          },
                          on: { datachange: _vm.getMapData },
                          model: {
                            value: _vm.mapObj.taskParam.workPermitPeriod,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.mapObj.taskParam,
                                "workPermitPeriod",
                                $$v
                              )
                            },
                            expression: "mapObj.taskParam.workPermitPeriod",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    ref: "carousel",
                    staticClass: "card-body",
                    staticStyle: { padding: "15px !important" },
                  },
                  [
                    _vm.mapObj.maps && _vm.mapObj.maps.length > 0
                      ? _c(
                          "q-carousel",
                          {
                            staticClass: "workPermit-carousel",
                            attrs: {
                              swipeable: "",
                              animated: "",
                              arrows: "",
                              "control-type": "regular",
                              "control-color": "grey-6",
                              infinite: "",
                            },
                            model: {
                              value: _vm.mapObj.slide,
                              callback: function ($$v) {
                                _vm.$set(_vm.mapObj, "slide", $$v)
                              },
                              expression: "mapObj.slide",
                            },
                          },
                          _vm._l(_vm.mapObj.maps, function (map, idx) {
                            return _c(
                              "q-carousel-slide",
                              {
                                key: idx,
                                attrs: { name: idx, "img-src": map.mapSrc },
                              },
                              [
                                [
                                  _vm.mapObj.activeTask === 1
                                    ? _vm._l(map.maps, function (item, idx) {
                                        return _c(
                                          "VueDraggableResizable",
                                          {
                                            key: idx,
                                            ref: "markImage",
                                            refInFor: true,
                                            staticClass: "mainMarkImage",
                                            class:
                                              item.idx ===
                                              _vm.mapObj.activeMarked
                                                ? "selected"
                                                : "",
                                            attrs: {
                                              resizable: false,
                                              parent: true,
                                              draggable: false,
                                              x:
                                                item.locationXcoordinate *
                                                _vm.mapRate *
                                                _vm.mapObj.ratio,
                                              y:
                                                item.locationYcoordinate *
                                                _vm.mapRate *
                                                _vm.mapObj.ratio,
                                              w: 40,
                                              h: 40,
                                              grid: [20, 20],
                                            },
                                            on: {
                                              activated: function ($event) {
                                                return _vm.onMarkedInfo(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("c-map-marker", {
                                              attrs: {
                                                isSelected:
                                                  item.idx ===
                                                  _vm.mapObj.activeMarked,
                                              },
                                            }),
                                            _c(
                                              "q-menu",
                                              {
                                                ref: "markedproxy" + item.idx,
                                                refInFor: true,
                                                attrs: {
                                                  "content-class":
                                                    "mainMapMenuLayer",
                                                  anchor: "top right",
                                                  self: "top left",
                                                },
                                              },
                                              _vm._l(
                                                _vm.mapObj.selectedWorkPermit
                                                  .workPermits,
                                                function (workPermit, idx) {
                                                  return _c(
                                                    "q-card",
                                                    {
                                                      key: idx,
                                                      class: [
                                                        "main-work-permit-card main-work-permit-card-cursor",
                                                        _vm.setStepClass(
                                                          workPermit.swpStepCd
                                                        ),
                                                      ],
                                                      attrs: {
                                                        flat: "",
                                                        bordered: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openWorkPermitPop(
                                                            workPermit
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "q-item",
                                                        {
                                                          staticClass:
                                                            "main-work-permit-card-section main-work-permit-card-item",
                                                        },
                                                        [
                                                          _c(
                                                            "q-item-section",
                                                            {
                                                              attrs: {
                                                                avatar: "",
                                                              },
                                                            },
                                                            [
                                                              _c("q-badge", {
                                                                attrs: {
                                                                  rounded: "",
                                                                  color:
                                                                    _vm.setStepColor(
                                                                      workPermit.swpStepCd
                                                                    ),
                                                                  label:
                                                                    workPermit.swpStepName,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "q-item-section",
                                                            [
                                                              _c(
                                                                "q-item-label",
                                                                _vm._l(
                                                                  _vm.setTypeIcons(
                                                                    workPermit.sopWorkTypeCds
                                                                  ),
                                                                  function (
                                                                    icon,
                                                                    iconIdx
                                                                  ) {
                                                                    return _c(
                                                                      "q-icon",
                                                                      {
                                                                        key: iconIdx,
                                                                        staticClass:
                                                                          "text-red",
                                                                        attrs: {
                                                                          name: icon,
                                                                          size: "25px",
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "q-card-section",
                                                        {
                                                          staticClass:
                                                            "main-work-permit-card-section",
                                                        },
                                                        [
                                                          _c("q-icon", {
                                                            staticClass:
                                                              "text-indigo",
                                                            attrs: {
                                                              name: "chevron_right",
                                                            },
                                                          }),
                                                          _c("b", [
                                                            _vm._v(
                                                              _vm._s(
                                                                workPermit.workSummary
                                                              )
                                                            ),
                                                          ]),
                                                          _vm._v(
                                                            " (" +
                                                              _vm._s(
                                                                workPermit.workTime
                                                              ) +
                                                              ") "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      })
                                    : _vm._e(),
                                ],
                              ],
                              2
                            )
                          }),
                          1
                        )
                      : _c("el-empty", {
                          attrs: {
                            "image-size": 435,
                            description:
                              _vm.$language("등록된 지도가 없습니다."),
                          },
                        }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
          [
            _c("div", { staticClass: "cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" " + _vm._s(_vm.$language("아차사고 현황")) + " "),
                _c(
                  "div",
                  { staticClass: "card-more main-header-input" },
                  [
                    _c("c-plant", {
                      attrs: {
                        type: "search",
                        name: "plantCdAcc",
                        isFirstValue: false,
                        label: "",
                      },
                      on: { datachange: _vm.getNearmiss },
                      model: {
                        value: _vm.plantCdAcc,
                        callback: function ($$v) {
                          _vm.plantCdAcc = $$v
                        },
                        expression: "plantCdAcc",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("c-table", {
                    ref: "gridAcc",
                    attrs: {
                      tableId: "gridAcc",
                      isTitle: false,
                      isDashboard: true,
                      columnSetting: false,
                      usePaging: false,
                      filtering: false,
                      isFullScreen: false,
                      hideBottom: true,
                      gridHeight: "310px",
                      columns: _vm.gridAcc.columns,
                      data: _vm.gridAcc.data,
                    },
                    on: { linkClick: _vm.linkClickAcc },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
          [
            _c("div", { staticClass: "cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(
                  " " + _vm._s(_vm.$language("통합개선 이행율 현황")) + " "
                ),
                _c(
                  "div",
                  { staticClass: "card-more main-header-input" },
                  [
                    _c("c-plant", {
                      attrs: {
                        type: "search",
                        label: "",
                        isFirstValue: false,
                        name: "plantCd4",
                      },
                      on: { datachange: _vm.datachange4 },
                      model: {
                        value: _vm.plantCd4,
                        callback: function ($$v) {
                          _vm.plantCd4 = $$v
                        },
                        expression: "plantCd4",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "card-more main-header-input main-header-input2",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        type: "year",
                        default: "today",
                        name: "actionCompleteRequestDate",
                      },
                      on: { input: _vm.getImprStatus },
                      model: {
                        value: _vm.actionCompleteRequestDate,
                        callback: function ($$v) {
                          _vm.actionCompleteRequestDate = $$v
                        },
                        expression: "actionCompleteRequestDate",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("apexchart", {
                    ref: "imprChart",
                    attrs: {
                      height: "300px",
                      type: "bar",
                      width: _vm.imprChart.chartWidth,
                      options: _vm.imprChart.chartOptions,
                      series: _vm.imprChart.series,
                    },
                    on: { dataPointSelection: _vm.selectedBarImp },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("c-notice-popup"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }